<template>
  <div>
    <div class="row page-title">
      <label class="Agency-Name-HFM-Co"
        >{{ ICRequestTaskDetails.AgencyName }} |
        {{ ICRequestTaskDetails.ExpectedHFMCode }}</label
      >
    </div>
    <div class="row icreview-component">
      <b-card class="col-lg-9 main-content">
        <div class="panel-list">
          <div class="panel-body">
            <b-row class="panel-title">
              <label class="col-lg-6 Intercompany-Agency"
                >InterCompany Agency Info</label
              >
            </b-row>
            <b-row class="panel-content">
              <b-col cols="lg-4">
                <label>INTERCOMPANY AGENCY NAME</label>
                <p>{{ ICRequestTaskDetails.AgencyName }}</p>
              </b-col>
              <b-col cols="lg-4">
                <label>HFM CODE</label>
                <p>{{ ICRequestTaskDetails.ExpectedHFMCode }}</p>
              </b-col>
              <b-col cols="lg-4">
                <label>Intercompany Agency Network/Region</label>
                <p>
                  {{
                    ICRequestTaskDetails.AgencyNetworkRegionCode != null
                      ? ICRequestTaskDetails.AgencyNetworkRegionCode.split(
                          "~"
                        )[1]
                      : ICRequestTaskDetails.AgencyNetworkRegion
                  }}
                </p>
              </b-col>
              <b-col cols="lg-4">
                <label>Country</label>
                <p>{{ ICRequestTaskDetails.AgencyCountry }}</p>
              </b-col>
              <!-- <b-col
                cols="lg-4"
                v-if="ICRequestTaskDetails.AgencyStreetAddress != null"
              >
                <label>Intercompany Agency Street Address</label>
                <p>{{ ICRequestTaskDetails.AgencyStreetAddress }}</p>
              </b-col> -->

              <b-col
                cols="lg-4"
                v-if="ICRequestTaskDetails.AgencyStreetAddressLine1 != null"
              >
                <label>Intercompany Agency Street Address Line 1</label>
                <p>{{ ICRequestTaskDetails.AgencyStreetAddressLine1 }}</p>
              </b-col>

              <b-col
                cols="lg-4"
                v-if="ICRequestTaskDetails.AgencyStreetAddressLine2 != null"
              >
                <label>Intercompany Agency Street Address Line 2</label>
                <p>{{ ICRequestTaskDetails.AgencyStreetAddressLine2 }}</p>
              </b-col>

              <b-col cols="lg-4" v-if="ICRequestTaskDetails.AgencyCity != null">
                <label>City</label>
                <p>{{ ICRequestTaskDetails.AgencyCity }}</p>
              </b-col>
              <b-col
                cols="lg-4"
                v-if="ICRequestTaskDetails.AgencyState != null"
              >
                <label>STATE</label>
                <p>{{ ICRequestTaskDetails.AgencyState }}</p>
              </b-col>
              <b-col
                cols="lg-4"
                v-if="ICRequestTaskDetails.AgencyZipCode != null"
              >
                <label>ZIP CODE</label>
                <p>{{ ICRequestTaskDetails.AgencyZipCode }}</p>
              </b-col>
              <b-col cols="lg-4">
                <label>INTERCOMPANY AGENCY CONTACT NAME</label>
                <p>{{ ICRequestTaskDetails.AgencyContact }}</p>
              </b-col>
              <b-col cols="lg-4">
                <label>Agency Contact Email</label>
                <p>{{ ICRequestTaskDetails.AgencyContactEmail }}</p>
              </b-col>
              <b-col cols="lg-4">
                <label>Intercompany Agency Counterparty (only if different from HFM code being requested).</label>
                <p v-if="ICRequestTaskDetails.CounterParty">{{ ICRequestTaskDetails.CounterParty }}</p>
                <p v-else>N/A</p>
              </b-col>
              <b-col cols="lg-4">
                <label>Additional Instructions</label>
                <p>{{ ICRequestTaskDetails.AdditionalInstructions }}</p>
              </b-col>
              <b-col cols="lg-4" v-if="ICRequestTaskDetails.Files != null">
                <label>InterCompany Locator</label>
                <div>
                  <div
                    v-for="file in ICRequestTaskDetails.Files"
                    :key="file.Name"
                  >
                    <span v-if="file.FullName">
                      <a
                        class="file-ref"
                        :href="file.Name"
                        @click.prevent="downloadItem(file)"
                        target="_blank"
                        download
                        >{{ file.Name }}</a
                      >
                    </span>
                    <span v-else>
                      <span>{{ file.Name }}</span>
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card>
      <div class="col-lg-3 approval-side-section">
        <div class="row">
          <p class="approve-reject-label">
            Approve or Reject Intercompany Agency Request
          </p>
          <p class="approve-reject-content">
            When approved, this request will be sent to the Financial Systems
            Team to be completed.
          </p>
          <div class="text-right" style="width: 100%">
            <button class="btn custom-button btn-primary" @click="Approve()">
              Approve
            </button>
          </div>
        </div>
        <div class="row reject-section">
          <label class="rejection-label">Rejection Comments</label>
          <b-textarea
            class="rejection-comments"
            placeholder="Enter Rejection Comments"
            v-model="Comments"
            v-bind:class="{
              errorborder: displaycommentserror && showCommentsError,
              noterrorborder: !displaycommentserror || !showCommentsError,
            }"
            @blur="SetDirty()"
            @focus="ResetDirty()"
            rows="5"
          >
          </b-textarea>
          <div
            class="error-message-format required-field"
            v-if="displaycommentserror && showCommentsError"
          >
            Required field
          </div>
          <div class="text-right" style="width: 100%; padding-top: 10px">
            <button
              class="btn custom-button btn-outline-primary"
              @click="Reject()"
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

import store from "@/store";
<style scope >
@media screen and (min-width: 1200px) {
  .icreview-component {
    padding: 1rem 3rem 0px !important;
    height: 600px !important;
  }
}

.file-ref {
  color: #00a1d2 !important;
}

.Agency-Name-HFM-Co {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.73px;
  color: #4d5161;
}

.approval-side-section {
  background-color: rgba(162, 169, 173, 0.05);
  margin-bottom: 2px;
  padding-right: 35px !important;
  -webkit-box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
  box-shadow: 0 1px 3px 0 rgb(9 30 66 / 12%);
  border: solid 1px rgba(239, 242, 244, 0.2);
  border-left: none;
  border-radius: 0px 8px 8px 0px;
}

.Intercompany-Agency {
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5161 !important;
  text-transform: none;
}

.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}

.file-ref {
  color: #00a1d2 !important;
}
.panel-list:not(.media-dropdowns) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.panel-list .panel-body .panel-title {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  width: 100%;
  margin-left: 0px;
  margin-bottom: 25px !important;
}
.panel-list .panel-body .panel-title label {
  color: #77a2bb;
  margin-bottom: 0px !important;
  padding-top: 13px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  padding-left: 0px !important;
  min-height: 35px;
}
.panel-list .panel-body .panel-title .btn {
  padding-left: 5px;
  padding-right: 5px;
}
.panel-list .panel-body .panel-title > div {
  padding-right: 0px !important;
  float: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}
.panel-list .panel-content label {
  text-transform: uppercase;
  color: #2c3865;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding-left: 0px;
}
.panel-list .panel-content p {
  text-transform: uppercase;
  color: #4d5161;
  font-size: 14px;
  font-weight: 600;
}
.panel-content.row > div > label:first-child {
  color: #a2a9ad !important;
  font-family: "Open Sans" !important;
  font-size: 10px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}
.panel-content.row > div > *:not(:first-child) {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
}
.panel-content.row > div > div {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.panel-list > .panel-body {
  margin-top: 1rem !important;
}
.panel-content.row > div {
  margin-bottom: 10px !important;
}
.edit-icon {
  height: 11px;
  width: 11px;
  color: #00a1d2;
}
.request-status {
  background-color: rgba(216, 216, 216, 0.2);
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  height: 135px;
  border-radius: 5px;
}
.status-bar {
  line-height: 2;
  color: #d5d7e0;
}
.request-status .status {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.request-status .date {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #a2a9ad;
}
.request-status .updatedby {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.request-status .comments {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #00a1d2;
}
</style>




<script>
import store from "@/store";
import { required } from "vuelidate/lib/validators";

export default {
  validations: {
    Comments: {
      required,
    },
  },
  data() {
    return {
      displaycommentserror: false,
      ICRequest: {},
      ICRequestTaskDetails: {},
      Error: false,
      ShowRejectModal: false,
      Comments: "",
      showCommentsError: false,
    };
  },

  mounted() {
    if (this.canApproveICRequestLevel1) {
      this.GetICRequestWithTaskDetails();
    }
    else {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    }
  },
  computed: {
    canApproveICRequestLevel1() {
      return this.$store.getters.canApproveICRequestLevel1;
    },
  },
  props: ["requestnumber"],

  methods: {
    SetDirty() {
      if (this.Comments.length > 0) {
        this.showCommentsError = false;
      } else {
        this.showCommentsError = true;
      }
    },
    ResetDirty() {
      this.showCommentsError = false;
    },
    async downloadItem(file) {
      this.$store
        .dispatch("client/DownloadICFile", file)
        .then((response) => {
          const blob = new Blob([response.data], { type: file.Extension });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.Name;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((ex) => {
          if (ex.response) {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex.response.data}`
            );
          } else {
            this.$store.dispatch(
              "progress/setErrorMessage",
              `Error while downloading file. ${ex}`
            );
          }
        });
    },
    GetData() {
      return {
        ID: parseInt(this.requestnumber),
        Comments: this.Comments,
        ActiveTaskID: this.ICRequestTaskDetails.ActiveTaskID,
        ProjectID: this.ICRequestTaskDetails.OmniflowProjectId,
        ProcessID: this.ICRequestTaskDetails.OmniflowProcessId,
      };
    },

    FilesUploaded() {
      window.console.log(JSON.stringify(this.ICRequestTaskDetails.Files));
      let html = [];
      if (this.DownloadableFiles) {
        this.ICRequestTaskDetails.Files.forEach((element) => {
          html.push(element);
        });
      } else {
        this.ICRequestTaskDetails.Files.forEach((element) => {
          html.push(element);
        });
      }
      return html;
    },

    Approve() {
      this.ShowRejectModal = false;
      this.$store
        .dispatch("client/ApproveICRequestLevel1", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.ICRequestTaskDetails.ExpectedHFMCode} has been approved`
          );
          this.$router.push("/IntercompanyAgencies");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while approving request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while approving request", ex);
          }
        });
    },

    Reject() {
      window.console.log(this.Comments);
      if (this.Comments.length > 0) {
        this.displaycommentserror = false;
        this.ShowRejectModal = false;
        this.$store
          .dispatch("client/RejectRequestICLevel1", this.GetData())
          .then(() => {
            store.dispatch(
              "progress/setSuccessMessage",
              `Request ${this.ICRequestTaskDetails.ExpectedHFMCode} has been rejected`
            );
            this.$router.push("/IntercompanyAgencies");
          })
          .catch((ex) => {
            window.console.log(JSON.stringify(ex.response));
            if (ex.response) {
              this.SetErrorMessage(
                "Error while rejecting request",
                ex.response.data
              );
            } else {
              this.SetErrorMessage("Error while rejecting request", ex);
            }
          });
      } else {
        this.displaycommentserror = true;
        this.showCommentsError = true;
      }
    },

    CancelAdd() {
      this.ShowRejectModal = false;
    },
    ShowReject() {
      this.ShowRejectModal = true;
    },
    CancelReject() {
      this.ShowRejectModal = false;
    },
    Cancel() {
      this.$router.push("/IntercompanyAgencies");
    },

    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.ICRequestTaskDetails.ExpectedHFMCode}. The error is ${ex}`
      );
    },

    GetICRequestWithTaskDetails() {
      this.$store
        .dispatch(
          "client/GetICRequestWithTaskDetails",
          parseInt(this.requestnumber)
        )
        .then((res) => {
          this.ICRequestTaskDetails = res.data;
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            this.$router.push({
              name: "error",
              params: {
                message: "UnAuthorized"
              },
            });
          }
          else {
            this.Error = true;
            alert(err);
          }
        });
    },
  },
};
</script>